<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['thinningSkin'])">
      <single-answer-question
        id="thinning-skin"
        title="label.thinningSkin"
        hint="info.thinningSkin"
        :value="thinningSkin"
        :options="$options.yesNoResponseOptions"
        :image="$options.faceThinningImage"
        image-alt="face thinning image"
        yes-no
        dysfunction="skin-appearance"
        @input="onFieldChange('thinningSkin', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

import faceThinningImage from '@/modules/questionnaire/assets/images/skin-appearance/thinning/face-thinning.svg';

export default {
  name: 'ThinningTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  faceThinningImage,
  props: {
    thinningSkin: {
      type: String,
      required: true
    }
  }
};
</script>
