<template>
  <thinning-template
    :thinning-skin="thinningSkin"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import ThinningTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/thinning/ThinningTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const FIELDS_TO_RESET = ['skinAppearanceDiscomfort'];

const FIELDS = [requiredField('thinningSkin'), ...FIELDS_TO_RESET.map(field)];

export default {
  name: 'Thinning',
  components: { ThinningTemplate },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    thinningSkin(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }

      this.showNextStep();
    }
  }
};
</script>
